import React from "react"
import styled from 'styled-components';
import MainTemplate from "templates/MainTemplate"
import SEO from "components/SEO/seo"

const StyledWraper=styled.div`
width:100%;
height:auto;
display:flex;
align-items: center;
justify-content: center;`

const StyledH1=styled.div`
width:100%;
height: calc(100vh - 390px);
display:flex;
align-items: center;
justify-content: center;`

const NotFoundPage = () => (
  <MainTemplate>
    <SEO title="404: Nie znaleziono" />
    <StyledWraper>
      <StyledH1>
    <h1>Nie znaleziono takiej strony</h1>
    </StyledH1>
    </StyledWraper>
  </MainTemplate>
)

export default NotFoundPage